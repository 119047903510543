import React from 'react'
import {Order} from "../components"


export function CloseContainer() {
    const style = {
        height: '100vh',
        padding: '0 3em'
    }
    return (
        <Order>
        <Order.Sub style={style}>The order form is currently closed! Please check my Instagram availability highlights to see when the next one will be posted.</Order.Sub>
        </Order>
    )
}
