import {Jumbotron} from "../components";
import * as ROUTES from "../constants/routes"

export function JumbotronContainer() {
    return (
        <>
            <Jumbotron>
                <Jumbotron.Info>
                    <Jumbotron.Header>Asian-Inspired <br/><Jumbotron.Name>Bento Cakes & Treats</Jumbotron.Name></Jumbotron.Header>
                    <Jumbotron.Description>Little cakes for any occasion!</Jumbotron.Description>
                    <Jumbotron.MenuButton to={ROUTES.MENU}>View Menu</Jumbotron.MenuButton> <Jumbotron.OrderButton to={ROUTES.ORDER}>Order Now</Jumbotron.OrderButton>
                </Jumbotron.Info>
                
                <Jumbotron.Logo src="/images/logo2.png" />
            </Jumbotron>
        </>
    )
}
