import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    background-color: #FFF2E8;
    padding: 2em 3em;
    line-height: 1.6;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    margin: 0 auto;    
    font-family: 'Open Sans', sans-serif;
    
    @media screen and (max-width: 1119px) {
      flex-direction: column;
    }
    @media screen and (max-width: 600px) {
      padding: 2em 0.1em;
  }

`;

export const Header = styled.h1`
    display: flex;
    margin: 0 auto;
    color: #EB9084;
    font-size: 3em;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 955px) {
      font-size: 2.8em;
    }

    @media screen and (max-width: 620px) {
      font-size: 2.6em;
    }
`;

export const Sub = styled.h2`
    display: flex;
    margin: 0 auto;
    font-size: 1.2em;
    color: #364045;
    text-align: center;
    padding: 0.6em 0;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 955px) {
      font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
      font-size: 1em;
    }

`;

export const Rules = styled.ul`
    display: flex;
    flex-direction: column;
    color: #364045;
    line-height: 2;
    font-size: 1em;
    padding-bottom: 2em;
`;

export const Rule = styled.li`
    display: flex;
    content: "\2022";
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 40em;
    padding: 2em;
    border-radius: 1em;
    margin: 0 auto;
    background-color: #fde6dc;
    justify-content: center;

    @media screen and (max-width: 1119px) {
      width: 80%;
    }

`;

export const Label = styled.label`
    display: flex;
    color: #FFF6F2;
    font-weight: bold;
    color: #364045;
    font-size: 1.2em;
    align-items: center;

    @media screen and (max-width: 955px) {
      font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
      font-size: 1em;
    }
`;

export const Input = styled.input`
    display: flex;
    padding: 1em;
    border-style: none;
    border-radius: 1em;
    background-color: #FFF6F2;
    margin: 1em 0;
    margin-right: 0.5em;
`;

export const InputSub = styled.span`
    display: flex;
    font-size: 0.85em;
    padding: 0 1em;
    color: #916865;
    font-weight: normal;
    
    @media screen and (max-width: 955px) {
      font-size: 0.9em;
    }

    @media screen and (max-width: 620px) {
      font-size: 0.8em;
    }
    
`;

export const Submit = styled.input.attrs({ 
    type: 'submit',
    value: 'Submit'
  })`
  background: #EB9084;
  color: #FFF6F2;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 1em;
  padding: 1em;
  width: 6.25em;
  border-color: transparent;
  transition: 0.5s;
  text-align: center;
  margin: 1em auto;

  :hover {
    background-color: #F1B0A7;
  }

  &:active {
    background-color: #fff;
  }
  `

  export const Next = styled.input.attrs({ 
    type: 'button',
    value: 'Next'
  })`
    background: #EB9084;
    color: #FFF6F2;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 1em;
    padding: 1em;
    width: 6.25em;
    border-color: transparent;
    transition: 0.5s;
    text-align: center;
    margin: 1em auto;

    :hover {
      background-color: #F1B0A7;;
    }

    :disabled {
      background-color: rgba(235, 144, 132, 0.25);
      cursor: not-allowed;
    }
  `

  export const Back = styled.input.attrs({ 
    type: 'button',
    value: 'Back'
  })`
    background: #EB9084;
    color: #FFF6F2;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 1em;
    padding: 1em;
    width: 6.25em;
    border-color: transparent;
    transition: 0.5s;
    text-align: center;
    margin: 1em auto;

    :hover {
      background-color: #F1B0A7;;
    }

    :disabled {
      background-color: rgba(235, 144, 132, 0.25);
      cursor: not-allowed;
    }
   `
   
  export const Important = styled.span`
    font-style: italic;
    font-weight: bold;
    color: #EB9084;
  `;

  export const Image = styled.img`
    height: 20em;
  `;

  export const SideJumbotron = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 auto;
    align-items: center;
    padding-right: 2em;

    @media screen and (max-width: 1119px) {
      width: 90%;
      flex-wrap: wrap;
      padding: 0;
    }
  `;

  export const Buttons = styled.div`
    display: flex;
  `;

