import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF2E8;
    overflow: hidden;
    padding: 2em 3em;
   
    @media screen and (max-width: 600px) {
        padding: 2em 1em;
    }

    @media screen and (max-width: 400px) {
        padding: 1em 0.5em;
    }
`;

export const Header = styled.h1`
    display: flex;
    margin: 0 auto;
    color: #364045;
    font-size: 3em;
    font-family: "Roboto", sans-serif;
    line-height: 1.5;

    :focus {
        outline: none !important;
    }

    @media screen and (max-width: 620px) {
        font-size: 2.8em;
    }
`;

export const MenuDescription = styled.p`
    display: flex;
    margin: 0.5em auto;
    color: #364045;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    line-height: 1.5;
`;
export const CategoryName = styled.h2`
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    color: #364045;
    margin-bottom: 0;
    line-height: 1.5;

    @media screen and (max-width: 1165px) {
        margin: 0 auto;
    }

    @media screen and (max-width: 620px) {
        font-size: 1.5em;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.3em;
    }
`;

export const CategoryDescription = styled.p`
    font-size: 1em;
    color: #364045;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    padding-bottom: 2em;


    @media screen and (max-width: 1165px) {
        margin: 0 auto;
        text-align: center;
    }
`;

export const Custom = styled.div`
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // margin: 0 auto;
    

    // @media screen and (max-width:1404px) {
        margin: 0 auto;
    // }
`;

export const CustomItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
    margin: 0 auto;


    @media screen and (max-width: 1165px) {
        align-items: center;
        flex-direction: column;
    }
`;

export const CustomItem = styled.div`
        display: flex;
        margin: 1em 0;
        width: 49%;
        height: auto;
        align-items: center;

        @media screen and (max-width: 1165px) {
            width:  510px;
        }
        
        @media screen and (max-width: 700px) {
            width: 390px;
        }

        @media screen and (max-width: 500px) {
        width: 320px;
        margin: 1em 0;
        }

        @media screen and (max-width: 280px) {
            width: 200px;
        }

`;

export const CustomGallery = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: yellow;
    margin: 2em 0;


    @media screen and (max-width:1165px) {
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: flex-start;
        width: 510px;
        overflow: scroll;
    }

    @media screen and (max-width: 700px) {
        width: 390px;
    }

    @media screen and (max-width: 500px) {
        width: 320px;
    }

    @media screen and (max-width: 280px) {
        width: 200px;
    }
`;

export const CustomImage = styled.img`
    display: flex;
    height: 199px;
    width: 300px;

    @media screen and (max-width: 1311px) {
        width: 250px;
        height: 166px;
    }
    @media screen and (max-width: 1165px) {
        width: 350px;
        height: 232px;
    }


`;


export const CustomDescription = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #364045;
    margin-top: 0;
    line-height: 1.5;
`;

export const Category = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


    @media screen and (max-width: 1165px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Item = styled.div`
    display: flex;
    margin: 1em 0;
    align-items: center;
    width: 49%;

    @media screen and (max-width: 1165px) {
        // width: 80%;
        justify-content: center;
    }
    

`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0.5em 2em 2em;
    border-style: solid;
    border-radius: 1em;
    border-color: #F4BBB0;

`;

export const Image = styled.img`
    display: flex;
    height: 264px;
    width: 450px;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 700px) {
        width: 320px;
        height: 208px;
    }

    @media screen and (max-width: 500px) {
        width: 256px;
        height: 176px;
    }

    @media screen and (max-width: 280px) {
        width: 180px;
        height: 119px;
    }
`;

export const ItemTitle = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    color: #EB9084;
`;
 
export const ItemName = styled.h3`
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6em;
    font-weight: bold;
    margin: 0.25em 0;
    color: #EB9084;
    font-family: 'Roboto', sans-serif;
   
    @media screen and (max-width: 1013px) {
        font-size: 23px;
    }

    @media screen and (max-width: 500px) {
        font-size: 20px;
    }
`;

export const Price = styled.h3`
    font-size: 1.5em;
    margin: 0.25em 0;
    line-height: 1.5;

    @media screen and (max-width: 1013px) {
        font-size: 23px;
    }

    @media screen and (max-width: 500px) {
        font-size: 20px;
    }

`;

export const Description = styled.p`
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #364045;
    margin-top: 0;
    // width: 34em;

    line-height: 1.5;

    // @media screen and (max-width: 1397px) {
    //     width: 30em;
    // }

    // @media screen and (max-width: 1269px) {
    //     width: 26em;
    // }


    // @media screen and (max-width: 700px) {
    //     width: 20em;
    // }

    // @media screen and (max-width: 500px) {
    //     width: 16em;
    // }
`;
 
