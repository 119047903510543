import {Gallery} from "../components"
import flavorsData from "../fixtures/flavorsData.json"
import cookiesData from "../fixtures/cookiesData.json"
import reviewsData from "../fixtures/reviewsData.json"
import React, {useState} from 'react'
import ImageFadeIn from "react-image-fade-in"
import LazyLoad from "react-lazy-load"

export function GalleryContainer({reviews, tier1, tier2, tier3, tier4}) {
    const [current, setCurrent] = useState(1);
    const length = reviews.length; 

    const [current1, setCurrent1] = useState(1);
    //const [current2, setCurrent2] = useState(1);
    const [current3, setCurrent3] = useState(1);
    const [current4, setCurrent4] = useState(1);

    const length4 = tier4.length; 
    const length3 = tier3.length; 
    //const length2 = tier2.length; 
    const length1 = tier1.length; 

    function next4() { setCurrent4(current4 === length4 ? 1 : current4 + 1); }
    function prev4() { setCurrent4(current4 === 1 ? length4 : current4 - 1); }

    function next3() { setCurrent3(current3 === length3 ? 1 : current3 + 1); }
    function prev3() { setCurrent3(current3 === 1 ? length3 : current3 - 1); }

    //function next2() { setCurrent2(current2 === length2 ? 1 : current2 + 1); }
    //function prev2() { setCurrent2(current2 === 1 ? length2 : current2 - 1); }

    function next1() { setCurrent1(current1 === length1 ? 1 : current1 + 1); }
    function prev1() { setCurrent1(current1 === 1 ? length1 : current1 - 1); }


    function next() { setCurrent(current === length ? 1 : current + 1); }
    function prev() { setCurrent(current === 1 ? length : current - 1); }

    return (
        <Gallery>
            <Gallery.Header>Gallery</Gallery.Header>

                {/* Custom Cakes */}
                {/* Tier 4 */}
                <Gallery.CustomImages id="custom">
                        <Gallery.PicContainer>
                            <Gallery.PicLeft onClick={prev4}/>
                                        {tier4.map((item) => (
                                            item.id === current4 ? <>
                                                <Gallery.Item key={item.id}> 
                                                    {/* <Gallery.CustomImage src={item.image} alt={item.alt}/> */}
                                                    <LazyLoad><ImageFadeIn height={480} width={720} alt={item.alt} src={item.image} /></LazyLoad> 
                                                </Gallery.Item> 
                                            </> : ""  
                                        ))}
                            <Gallery.PicRight onClick={next4}/>
                        </Gallery.PicContainer>

                        <Gallery.CustomSqImages>
                            {tier4.map((item) => (
                                <Gallery.Item key={item.id}>
                                    <Gallery.CustomSqImage src={item.sqimg} alt={item.alt}/>
                                </Gallery.Item>
                            ))}
                        </Gallery.CustomSqImages>

                        <Gallery.Info>
                            <Gallery.ItemPrice>Custom $40 and Up</Gallery.ItemPrice>
                            <Gallery.CategoryDescription>2 or More Characters</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Anime/Hard Characters</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>3D Characters</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Many Details & Colors</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Detailed Skies/Paintings</Gallery.CategoryDescription>
                        </Gallery.Info>
                </Gallery.CustomImages>


                    {/* Tier 3 */}
                        <Gallery.CustomImages>
                            <Gallery.Info>
                                <Gallery.ItemPrice>Custom $32 - $39</Gallery.ItemPrice>
                                <Gallery.CategoryDescription>Easy - Medium Characters/Drawings</Gallery.CategoryDescription>
                                <Gallery.CategoryDescription>Simple Brushstroke</Gallery.CategoryDescription>
                                <Gallery.CategoryDescription>Simple Paintings/Florals</Gallery.CategoryDescription>
                            </Gallery.Info>

                            <Gallery.PicContainer>
                                <Gallery.PicLeft onClick={prev3}/>
                                            {tier3.map((item) => (
                                                item.id === current3 ? <>
                                                    <Gallery.Item key={item.id}>
                                                        <LazyLoad><ImageFadeIn height={480} width={720} opacityTransition={1} alt={item.alt} src={item.image} /></LazyLoad> 
                                                        {/* <Gallery.CustomImage src={item.image} /> */}
                                                    </Gallery.Item> 
                                                </> : ""  
                                            ))}
                                <Gallery.PicRight onClick={next3}/>
                            </Gallery.PicContainer>

                            <Gallery.CustomSqImages>
                            {tier3.map((item) => (
                                <Gallery.Item key={item.id}>
                                    <Gallery.CustomSqImage src={item.sqimg} />
                                </Gallery.Item>
                            ))}
                        </Gallery.CustomSqImages>
                        </Gallery.CustomImages>

                    {/* Tier 2 deleted */}                     
                                    
                    {/* Tier 1 */}
                    <Gallery.CustomImages>
                        
                        <Gallery.PicContainer>
                            <Gallery.PicLeft onClick={prev1}/>                
                                        {tier1.map((item) => (
                                            item.id === current1 ? <>
                                                <Gallery.Item key={item.id}> 
                                                    {/* <Gallery.CustomImage src={item.image} alt={item.alt}/> */}
                                                    <LazyLoad><ImageFadeIn height={480} width={720} alt={item.alt} src={item.image} /></LazyLoad> 
                                                </Gallery.Item> 
                                            </> : ""  
                                        ))}
                            <Gallery.PicRight onClick={next1}/>
                        </Gallery.PicContainer>
                        
                        <Gallery.CustomSqImages>
                            {tier1.map((item) => (
                                <Gallery.Item key={item.id}>
                                    <Gallery.CustomSqImage src={item.sqimg} alt={item.alt}/>
                                </Gallery.Item>
                            ))}
                        </Gallery.CustomSqImages>

                        <Gallery.Info>
                            <Gallery.ItemPrice>Custom $25 - $31</Gallery.ItemPrice>
                            <Gallery.CategoryDescription>Minimal</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Writing + Borders</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Accents</Gallery.CategoryDescription>
                            <Gallery.CategoryDescription>Few details + colors </Gallery.CategoryDescription>
                        </Gallery.Info>

                       
                    </Gallery.CustomImages>


                    {/* REVIEWS SECTION */}
                    <Gallery.Reviews>
                            <Gallery.ReviewsHeader>Reviews</Gallery.ReviewsHeader>
                                <Gallery.ReviewsContainer>
                                <Gallery.Left onClick={prev}/>
                                <Gallery.ReviewsInfo>

                                    {reviewsData.map((item) => (
                                            <>
                                            {item.id===current ? 
                                                <>
                                                    <Gallery.Review key={item.id}>{item.review}</Gallery.Review>
                                                    <Gallery.Verified><Gallery.Check />Verified Purchase</Gallery.Verified>
                                                </> : ""}
                                                
                                            </>     
                                    ))}
                                </Gallery.ReviewsInfo>

                                <Gallery.Right onClick={next}   />
                                </Gallery.ReviewsContainer>
                    </Gallery.Reviews>

                    <Gallery.SpecialtyImages id="specialty">
                        {flavorsData.map((item) => (
                        <>
                            {item.specialty ? 
                                <Gallery.Item key={item.id}>
                                    <Gallery.SpecialtyImage src={item.himg} alt={item.alt} /> 
                                    <Gallery.SpecialtySqImage src={item.sqimg} alt={item.alt} />  
                                    <Gallery.Overlay> 
                                    <Gallery.ItemName>
                                        {item.title}
                                        <Gallery.ItemPrice>{item.price}</Gallery.ItemPrice>
                                    </Gallery.ItemName>
                                    </Gallery.Overlay>
                                </Gallery.Item> 
                            : ""}
                        </>
                        ))}
                    </Gallery.SpecialtyImages>
                {/* </Gallery.Category> */}

                {/* <Gallery.Category> */}
                    <Gallery.OtherImages id="other">
                        {cookiesData.map((item) => (
                        <>
                            {item.noshow ? null : 
                                <Gallery.Item key={item.id}>
                                    <Gallery.OtherImage src={item.sqimg} alt={item.alt} />   
                                    <Gallery.Overlay> 
                                    <Gallery.ItemName>
                                        {item.title}
                                        <Gallery.ItemPrice>{item.price}</Gallery.ItemPrice>
                                    </Gallery.ItemName>
                                    </Gallery.Overlay>
                                </Gallery.Item> 
                            }
                        </>
                        ))}
                    </Gallery.OtherImages>
                {/* </Gallery.Category> */}
        </Gallery>
    )
}
