import React from 'react'
import {Container, 
    Header, MenuDescription, 
    CategoryName, CategoryDescription, 
    Category, 
    Custom, CustomItems, CustomGallery, CustomImage, CustomItem, CustomDescription,
    Item,
    Info, Image, ItemTitle, ItemName, Price, Description} from "./styles/pricing"

export default function Pricing({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

Pricing.Header = function PricingHeader({...restProps}) {
    return <Header {...restProps} />
}

Pricing.MenuDescription = function PricingMenuDescription({children, ...restProps}) {
    return <MenuDescription {...restProps}>{children}</MenuDescription>
}

Pricing.CategoryName = function PricingCategoryName({children, ...restProps}) {
    return <CategoryName {...restProps}>{children}</CategoryName>
}

Pricing.CategoryDescription = function PricingCategoryDescription({children, ...restProps}) {
    return <CategoryDescription {...restProps}>{children}</CategoryDescription>
}

Pricing.Category = function PricingCategory({children, ...restProps}) {
    return <Category {...restProps}>{children}</Category>
}

Pricing.Custom = function PricingCustom({children, ...restProps}) {
    return <Custom {...restProps}>{children}</Custom>
}

Pricing.CustomItems = function PricingCustomItems({children, ...restProps}) {
    return <CustomItems {...restProps}>{children}</CustomItems>
}

Pricing.CustomGallery = function PricingCustomGallery({children, ...restProps}) {
    return <CustomGallery {...restProps}>{children}</CustomGallery>
}

Pricing.CustomImage = function PricingCustomImage({children, ...restProps}) {
    return <CustomImage {...restProps}>{children}</CustomImage>
}

Pricing.CustomItem = function PricingCustomItem({children, ...restProps}) {
    return <CustomItem {...restProps}>{children}</CustomItem>
}

Pricing.Item = function PricingItem({children, ...restProps}) {
    return <Item {...restProps}>{children}</Item>
}

Pricing.Info = function PricingInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}


Pricing.Image = function PricingImage({children, ...restProps}) {
    return <Image {...restProps}>{children}</Image>
}

Pricing.ItemTitle = function PricingItemTitle({children, ...restProps}) {
    return <ItemTitle {...restProps}>{children}</ItemTitle>
}

Pricing.ItemName = function PricingItemName({children, ...restProps}) {
    return <ItemName {...restProps}>{children}</ItemName>
}

Pricing.Price = function PricingPrice({children, ...restProps}) {
    return <Price {...restProps}>{children}</Price>
}

Pricing.Header = function PricingHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Pricing.Description = function PricingDescription({children, ...restProps}) {
    return <Description {...restProps}>{children}</Description>
}

Pricing.CustomDescription = function PricingCustomDescription({children, ...restProps}) {
    return <CustomDescription {...restProps}>{children}</CustomDescription>
}

Pricing.Price = function PricingPrice({children, ...restProps}) {
    return <Price {...restProps}>{children}</Price>
}
