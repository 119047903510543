import React from 'react'
import { NavbarContainer } from '../containers/navbar'
import { FaqContainer } from '../containers/faq'
import { FooterContainer } from '../containers/footer'

export default function Faq() {
    return (
        <div>
            <NavbarContainer />
            <FaqContainer />
            <FooterContainer />
        </div>
    )
}
