import {Container, Section, Category, Expand, Close, Item, QGroup, Line, Header, Answer, Question} from "./styles/faq"
import React from 'react'


export default function Faq({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

Faq.Section = function FaqSection({children, ...restProps}) {
    return <Section {...restProps}>{children}</Section>
}

Faq.Item = function FaqItem({children, ...restProps}) {
    return  <Item {...restProps}>{children}</Item>
}

Faq.QGroup = function FaqQGroup({children, ...restProps}) {
    return <QGroup {...restProps}>{children}</QGroup>
}

Faq.Category = function FaqCategory({children, ...restProps}) {
    return <Category {...restProps}>{children}</Category>
}

Faq.Expand = function FaqExpand({to, children, ...restProps}) {

    return <Expand {...restProps}>{children}</Expand>
}

Faq.Close = function FaqClose({to, children, ...restProps}) {
    return <Close {...restProps} to={to}>{children}</Close>
}

Faq.Line = function FaqLine({children, ...restProps}) {
    return <Line {...restProps}>{children}</Line>
}


Faq.Header = function FaqHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Faq.Answer = function FaqAnswer({children, ...restProps}) {

    return <Answer {...restProps}>{children}</Answer>;
}

Faq.Question = function FaqQuestion({children, ...restProps}) {
    return <Question {...restProps}>{children}</Question>
}