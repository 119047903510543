import React from 'react'
import {Container, Header, Sub, Rules, Rule, Form, Label, Input, InputSub,
    Buttons, Back, Next, Submit, Important, Image, SideJumbotron} from "./styles/order"

export default function Order({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}
Order.Header = function OrderHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}
Order.Sub = function OrderSub({children, ...restProps}) {
    return <Sub {...restProps}>{children}</Sub>
}
Order.Rules = function OrderRules({children, ...restProps}) {
    return <Rules {...restProps}>{children}</Rules>
}
Order.Rule = function OrderRule({children, ...restProps}) {
    return <Rule {...restProps}>{children}</Rule>
}

Order.Form = function OrderForm({children, ...restProps}) {
    return <Form {...restProps}>{children}</Form>
}

Order.Label = function OrderLabel({children, ...restProps}) {
    return <Label {...restProps}>{children}</Label>
}

Order.Input = function OrderInput({...restProps}) {
    return <Input {...restProps} />
}

Order.InputSub = function OrderInputSub({children, ...restProps}) {
    return <InputSub {...restProps}>{children}</InputSub>
}

Order.Buttons = function OrderButtons({children, ...restProps}) {
    return <Buttons {...restProps}>{children}</Buttons>
}

Order.Back = function OrderBack({children, ...restProps}) {
    return <Back {...restProps}>{children}</Back>
}

Order.Next = function OrderNext({children, ...restProps}) {
    return <Next {...restProps}>{children}</Next>
}

Order.Submit = function OrderSubmit({children, ...restProps}) {
    return <Submit {...restProps}>{children}</Submit>
}

Order.Important = function OrderImportant({children, ...restProps}) {
    return <Important {...restProps}>{children}</Important>
}

Order.Image = function OrderImage({...restProps}) {
    return <Image {...restProps} />
}

Order.SideJumbotron = function OrderSideJumbotron({children, ...restProps}) {
    return <SideJumbotron {...restProps}>{children}</SideJumbotron>
}
