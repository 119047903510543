import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    justify-content: center;
`;

export const Item = styled.div`
    display: flex;
    background-color: #FFF6F2;
    margin: 1em 0;
    padding-bottom: 1em;
    border-radius: 1em;
    align-items: center;

    @media screen and (max-width: 840px) {
        flex-direction: column-reverse;
    }
`;

export const Header = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    display: flex;
    margin: 0 auto;
    color: #364045;
    padding: 1em 0;

    @media screen and (max-width: 955px) {
        font-size: 1.8em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1.6em;
    }

`;

export const Sub = styled.p`
    display: flex;
    text-align: center;
    color: #364045;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
`;

export const Group = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 840px) {
        flex-direction: column;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    color: #364045;

    @media screen and (max-width: 620px) {
        text-align: center;
    }
`;

export const ItemName = styled.h2`
    font-family: 'Roboto', sans-serif;
    display: flex;
    padding: 1em;
    font-size: 1.2em;
    margin-bottom: 0;
    color: #364045;
    
    @media screen and (max-width: 840px) {
        font-size: 1.1em;
        margin: 0 auto;
    }
`;

export const ItemDescription = styled.p`
    display: flex;
    padding-left: 20px;
    font-size: 1em;
    margin-top: 0;

    @media screen and (max-width: 840px) {
        text-align: center;
        padding: 0 1em;
    }
    
`;

export const Image = styled.img`
    display: flex;
    height: 11em;
    width: 11em;
    padding: 1em;
    border-radius: 2em;
`

export const Select = styled.select`
    display: flex;
    float: right;
    padding: 10px;
    border-radius: 10px;
    margin-left: 20px;

    @media screen and (max-width: 840px) {
        margin: 0;
    }


`;

export const Option = styled.option``;

export const Additional = styled.input`
    display: flex;
    font-family: 'Roboto', sans-serif;
    border-radius: 0.5em;
`;

export const Candle = styled.div`
    display: flex;
`;
