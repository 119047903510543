import {Container, Info, Header, Name, Description, Image} from "./styles/about"
import React from 'react'

export default function About({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

About.Info = function AboutInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}

About.Header = function AboutHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

About.Name = function AboutName({children, ...restProps}) {
    return <Name {...restProps}>{children}</Name>
}

About.Description = function AboutDescription({children, ...restProps}) {
    return <Description {...restProps}>{children}</Description>
}

About.Image = function AboutImage({ ...restProps}) {
    return <Image {...restProps} />
}