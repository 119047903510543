import React from 'react'
import {HomeGallery} from "../components"
import LazyLoad from 'react-lazy-load'

export function HomeGalleryContainer() {
    return (
        <>
        <HomeGallery>
            <HomeGallery.ImageGallery>
                                <HomeGallery.Category>
                                    <HomeGallery.ImageLink href="/gallery/#custom">
                                        <HomeGallery.CategoryName>Custom Cakes</HomeGallery.CategoryName>
                                        <LazyLoad><HomeGallery.Image src="/images/cakes/homecakes/custom.png" alt="custom cake" /></LazyLoad>
                                    </HomeGallery.ImageLink>
                                </HomeGallery.Category>

                                <HomeGallery.Category>
                                    <HomeGallery.ImageLink href="/gallery/#specialty">
                                        <HomeGallery.CategoryName>Specialty Cakes</HomeGallery.CategoryName>
                                        <LazyLoad><HomeGallery.Image src="/images/cakes/homecakes/specialty.png" alt="specialty cake" /></LazyLoad>
                                    </HomeGallery.ImageLink>
                                </HomeGallery.Category>

                                <HomeGallery.Category>
                                    <HomeGallery.ImageLink href="/gallery/#other">
                                        <HomeGallery.CategoryName>Baked Goodies</HomeGallery.CategoryName>
                                        <LazyLoad><HomeGallery.Image src="/images/cakes/homecakes/cookies.png" alt="other baked goods" /></LazyLoad>
                                    </HomeGallery.ImageLink>
                                </HomeGallery.Category>
            </HomeGallery.ImageGallery>
            
            <HomeGallery.Header>Explore Bakery</HomeGallery.Header>
        </HomeGallery>
        </>
    )
}
