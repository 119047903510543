import React from 'react'
import {Container, Header, ImageGallery, Image, ImageLink, Category, CategoryName} from "./styles/homegallery"

export default function HomeGallery({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

HomeGallery.Header = function HomeGalleryHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

HomeGallery.ImageGallery = function HomeGalleryImageGallery({children, ...restProps}) {
    return <ImageGallery {...restProps}>{children}</ImageGallery>
}


HomeGallery.Category = function HomeGalleryCategory({children, ...restProps}) {
    return <Category {...restProps}>{children}</Category>
}

HomeGallery.CategoryName = function HomeGalleryCategoryName({children, ...restProps}) {
    return <CategoryName {...restProps}>{children}</CategoryName>
}

HomeGallery.Image = function HomeGalleryImage({...restProps}) {
    return <Image {...restProps} />
}

HomeGallery.ImageLink = function HomeGalleryImageLink({children, ...restProps}) {
    return <ImageLink {...restProps}>{children}</ImageLink>
}
