import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #FFF2E8;
    align-items: center;
    padding: 2em 3em;
    overflow: hidden;

    @media screen and (max-width: 620px) {
        padding: 2em 1em;
    }

    @media screen and (min-width:1441px) {
        height: 100vh;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    width: 42em;

    @media screen and (max-width: 1375px) {
        margin: 0 auto;
        text-align: center;
    }

    @media screen and (max-width: 995px) {
        width: 40em;
    }

    @media screen and (max-width: 620px) {
        width: 38em;
        padding: 0 1em;
    }
`;

export const Header = styled.h1`
    color: #364045;
    font-family: 'Roboto', sans-serif;
    font-size: 3em;

    @media screen and (max-width: 620px) {
        font-size: 2.8em;
    }

`;

export const Name = styled.span`
    color: #EB9084;
`;

export const Description = styled.p`
    color: #364045;
    line-height: 1.5;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
`;

export const Image = styled.img`
    display: flex;
    width: 32em;
    height: 42em;
    border-radius: 1em;

    @media screen and (max-width: 1375px) {
        margin: 2em auto;
    }

    @media screen and (max-width: 955px) {
        width: 30em;
        height: 40em;
    }

    @media screen and (max-width: 620px) {
        width: 23em;
        height: 30em;
    }
`;