import React from 'react'
import { FooterContainer } from '../containers/footer'
import { NavbarContainer } from '../containers/navbar'
import { OrderContainer } from '../containers/order'
import { CloseContainer } from '../containers/close'


export default function Order() {
    
    let form = false;
    

    return (
        <>
            <NavbarContainer/>
            {form ? <OrderContainer/> : <CloseContainer/>}
            <FooterContainer />
        </>
    )
}
