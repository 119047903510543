import React from 'react'
import {Container, Gallery, Item, Header, Info, Title, Description, Form, Wait, Pay, PickUp, Enjoy} from "./styles/works"
export default function Works({children, ...restProps}) { 
    return <Container {...restProps}>{children}</Container>
}

Works.Gallery = function WorksGallery({children, ...restProps}) {
    return <Gallery {...restProps}>{children}</Gallery>
}

Works.Item = function WorksItem({children, ...restProps}) {
    return <Item {...restProps}>{children}</Item>
}

Works.Header = function WorksHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Works.Info = function WorksInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}

Works.Title = function WorksTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>
}

Works.Description = function WorksDescription({children, ...restProps}) {
    return <Description {...restProps}>{children}</Description>
}

Works.Form = function WorksForm({...restProps}) {
    return <Form {...restProps} />
}

Works.Wait = function WorksWait({...restProps}) {
    return <Wait {...restProps} />
}

Works.Pay = function WorksPay({...restProps}) {
    return <Pay {...restProps} />
}

Works.PickUp = function WorksPickUp({...restProps}) {
    return <PickUp {...restProps} />
}

Works.Enjoy = function WorksEnjoy({...restProps}) {
    return <Enjoy {...restProps} />
}
