import React from 'react'
import { Nav, Logo, LogoLink, Menu, ItemLink, ButtonLink, Bars, Times } from './styles/navbar'

export default function Navbar({children, ...restProps}) {
    return <Nav {...restProps}>{children}</Nav>
}

Navbar.Logo = function NavbarLogo({...restProps}) {
    return <Logo {...restProps} />
}

Navbar.Menu = function NavbarMenu({children, ...restProps}) {
    return <Menu {...restProps}>{children}</Menu>
}

Navbar.Bars = function NavbarBars({to, children, ...restProps}) {
    return <Bars {...restProps} to={to}>{children}</Bars>
}

Navbar.Times = function NavbarTimes({to, children, ...restProps}) {
    return <Times {...restProps} to={to}>{children}</Times>
}


Navbar.ItemLink = function NavbarItemLink({to, children, ...restProps}) {
    return <ItemLink {...restProps} to={to}>{children}</ItemLink>
}

Navbar.ButtonLink = function NavbarButtonLink({to, children, ...restProps}) {
    return <ButtonLink {...restProps} to={to}>{children}</ButtonLink>
}

Navbar.LogoLink = function NavbarLogoLink({to, children, ...restProps}) {
    return <LogoLink {...restProps} to={to}>{children}</LogoLink>
}