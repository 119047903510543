import styled from "styled-components";
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    background-color: #FFF2E8;
    justify-content: space-between;
    align-items: center;
    padding: 2em 3em;
    padding-bottom: 2.75em;
    overflow: hidden;
    
    @media screen and (max-width: 860px) {
        flex-direction: column;
    }

    @media screen and (max-width: 620px) {
        padding: 2em 1em;
    }
`;

export const Info = styled.div`
    flex-direction: column;

    @media screen and (max-width: 860px) {
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }
`;

export const Logo = styled.img`
    width: 25em;
    height: 25em;
    padding-top: 3em;

    @media screen and (max-width: 995px) {
        width: 23em;
        height: 23em;
    }
`;

export const Header = styled.h1`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    color: #364045;
    font-size: 4em;
    margin: 0 auto;

    @media screen and (max-width: 995px) {
        font-size: 3.5em;
    }

    @media screen and (max-width: 620px) {
        font-size: 2.9em;
    }
`;

export const Name = styled.span`
    display: flex;
    color: #EB9084;
`;

export const Description = styled.h2`
    display: flex;
    flex-direction: column;
    color: #364045;
    font-size: 1.75em;
    margin-bottom: 2.75em;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-width: 995px) {
        font-size: 1.65em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1.4em;
    }
`;

export const MenuButton = styled(ReachRouterLink)`
    font-family: 'Roboto', sans-serif;
    background-color: rgba(54, 64, 69, 1);
    padding: 1.8em;
    text-decoration: none;
    color: #FFF6F2;
    font-size: 1.1em;
    border-radius: 1em;
    font-weight: bold;
    transition: 0.5s;

    :hover {
        background-color: rgba(54, 64, 69, 0.85); 
    }

    @media screen and (max-width: 995px) {
        padding: 1.65em;
    }

    @media screen and (max-width: 620px) {
        padding: 1.4em;
    }
`;

export const OrderButton = styled(ReachRouterLink)`
    font-family: 'Roboto', sans-serif;
    background-color: rgba(235, 144, 132, 1);
    padding: 1.8em;
    text-decoration: none;
    color: #FFF6F2;
    font-size: 1.1em;
    border-radius: 1em;
    font-weight: bold;
    transition: 0.5s;

    :hover {
        background-color: rgba(235, 144, 132, 0.85);
    }

    @media screen and (max-width: 995px) {
        padding: 1.65em;
    }

    @media screen and (max-width: 620px) {
        padding: 1.4em;
    }
`;
