import React from 'react'
import {Footer} from "../components"

export function FooterContainer() {
    return (
        <Footer>
            <Footer.Copyright>The Little Baker SJ <Footer.CopyrightIcon /> 2021 All Rights Reserved</Footer.Copyright>
            <Footer.Socials>
                <Footer.SocialsLink href="https://www.instagram.com/thelittlebaker_sj" target="_blank">
                    <Footer.Instagram/>
                </Footer.SocialsLink>
                
                <Footer.SocialsLink href="https://www.tiktok.com/@thelittlebaker_sj" target="_blank">
                    <Footer.Tiktok />
                </Footer.SocialsLink>
            </Footer.Socials>
        </Footer>
    )
}
