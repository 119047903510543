export const HOME = "/";
export const ABOUT = "/about";
export const MENU = "/menu";
export const BAKERY = "/bakery";
export const ORDER = "/order";
export const FAQ = "/faq";
export const GALLERY = "/gallery";
export const FLAVORS = "/flavors";




