import React from 'react'
import {About} from "../components"

export function AboutContainer() {
    return (
        <>
            <About>
                <About.Info>
                    <About.Header>Hi everyone, my name is <About.Name>Tina</About.Name></About.Header>
                    <About.Description>
                        I’m the sole owner of The Little Baker! 
                        I’m 23 years old and a Vietnamese American female. Ever since I was young, I loved baking and eating sweets. 
                        Some of my fondest childhood memories include successfully making an apple pie for the first time and 
                        attending a summer cooking class!
                    </About.Description>
                    <About.Description>
                        Like many people, the pandemic left me feeling lost and empty. Baking was the only thing that kept me sane. 
                        One day, I discovered the most adorable little cakes on social media and decided to try making them with ube 
                        chiffon cake. Although they definitely weren't the prettiest, I gave them to my friends, and their praise got 
                        me thinking...why wasn't there anyone selling these cakes in the Bay Area? 
                    </About.Description>
                    <About.Description>
                        For months, I worked hard to transform my idea into a reality. I designed the logo and order forms, tested recipes, 
                        and practiced decorating cakes until late at night. I decided to call my business “The Little Baker” because the cakes 
                        are small, and well, I'm small too (I'm 4'10").
                    </About.Description>
                    <About.Description>
                        I adore my little business because it truly warms my heart to see how happy my cakes make my customers.  And I love my cakes 
                        because they aren't too big or small - they're just enough. They're the perfect treat for any occasion: birthdays, anniversaries, 
                        graduations, holidays...or even no occasion at all! I always say it's never a bad time to treat yourself. Although I still have 
                        a lot to learn, I have no words for the support and love I've received. I can't wait to see my business grow further and share 
                        my love of baking with you all ❤
                    </About.Description>
                </About.Info>
                <About.Image src="/images/about_tina.png" alt="tina the baker" />
            </About>
        </>
    )
}

