import React from 'react'
import {Container, Item, Header, Sub, Group,
        Info, ItemName, ItemDescription, Image, Select, Option, Additional, Candle} from "./styles/choices"

export default function Choices({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

Choices.Item = function ChoicesItem({children, ...restProps}) {
    return <Item {...restProps}>{children}</Item>
}

Choices.ItemDescription = function ChoicesItemDescription({children, ...restProps}) {
    return <ItemDescription {...restProps}>{children}</ItemDescription>
}

Choices.Header = function ChoicesHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Choices.Sub = function ChoicesSub({children, ...restProps}) {
    return <Sub {...restProps}>{children}</Sub>
}

Choices.Group = function ChoicesGroup({children, ...restProps}) {
    return <Group {...restProps}>{children}</Group>
}

Choices.Info = function ChoicesInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}

Choices.ItemName = function ChoicesItemName({children, ...restProps}) {
    return <ItemName {...restProps}>{children}</ItemName>
}

Choices.Image = function ChoicesImage({...restProps}) {
    return <Image {...restProps} />
}

Choices.Select = function ChoicesSelect({children, ...restProps}) {
    return <Select {...restProps}>{children}</Select>
}

Choices.Option = function ChoicesOption({children, ...restProps}) {
    return <Option {...restProps}>{children}</Option>
}

Choices.Additional = function ChoicesAdditional({children, ...restProps}) {
    return <Additional {...restProps}>{children}</Additional>
}

Choices.Candle = function ChoicesCandle({children, ...restProps}) {
    return <Candle {...restProps}>{children}</Candle>
}