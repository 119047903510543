import React from 'react'
import {Home, Gallery, Order, Menu, About, Faq} from "./pages"
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as ROUTES from "./constants/routes"

export default function App() {

    return(
        <>
            <Router>
                <Switch>
                    <Route exact path = {ROUTES.HOME}>
                        <Home />
                    </Route>
                </Switch>

                <Switch>
                    <Route path = {ROUTES.ORDER}>
                        <Order />
                    </Route>
                </Switch>

                <Switch>
                    <Route path = {ROUTES.MENU}>
                        <Menu />
                    </Route>
                </Switch>

                <Switch>
                    <Route path = {ROUTES.GALLERY}>
                        <Gallery />
                    </Route>
                </Switch>

                <Switch>
                    <Route path = {ROUTES.ABOUT}>
                        <About />
                    </Route>
                </Switch>

                <Switch>
                    <Route path = {ROUTES.FAQ}>
                        <Faq />
                    </Route>
                </Switch>
            </Router>
        </>
    )
}
