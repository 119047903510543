import styled from "styled-components";
import {FaWpforms, FaClock, FaMoneyCheck, FaPeopleCarry, FaSmileBeam} from 'react-icons/fa'


export const Container = styled.section`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff2e8;
    padding: 2em 3em;
    overflow: hidden;

    @media screen and (max-width: 620px) {
        padding: 2em 1em;
    }
`;

export const Gallery = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const Item = styled.div`
    text-align: center;
    padding: 1em;
    margin: 0 auto;
`;

export const Header = styled.h1`
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    font-size: 3em;
    color: #364045;


    padding: 0.55em 0;

`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5;
`;

export const Title = styled.h2`
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    margin: 0 auto;
    padding: 0.2em 0;
    color: #EB9084;
`;

export const Description = styled.p`
    font-family: 'Open Sans', sans-serif;
    color: #364045;
    margin: 0 auto;
    font-size: 1em;
    width: 14em;
    line-height: 1.5;
`;

export const Form = styled(FaWpforms)`
    font-size: 1.2em;
    color: #fff6f2;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 0.75em;
    background-color: #EB9084;

    @media screen and (max-width: 995px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1em;
    }
`;

export const Wait = styled(FaClock)`
    font-size: 1.25em;
    color: #fff6f2;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 0.75em;
    background-color: #EB9084;
    
    @media screen and (max-width: 995px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1em;
    }
`;

export const Pay = styled(FaMoneyCheck)`
    font-size: 1.25em;
    color: #fff6f2;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 0.75em;
    background-color: #EB9084;

    @media screen and (max-width: 995px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1em;
    }
`;

export const PickUp = styled(FaPeopleCarry)`
    font-size: 1.25em;
    color: #fff6f2;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 0.75em;
    background-color: #EB9084;

    @media screen and (max-width: 995px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1em;
    }
`;

export const Enjoy = styled(FaSmileBeam)`
    font-size: 1.25em;
    color: #fff6f2;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 0.75em;
    background-color: #EB9084;

    @media screen and (max-width: 995px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 620px) {
        font-size: 1em;
    }
`;