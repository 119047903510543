import React from 'react'
import {Pricing} from "../components"
import flavorsData from "../fixtures/flavorsData.json"
import cookiesData from "../fixtures/cookiesData.json"
import menuCustomData from "../fixtures/menuCustomData.json"
import LazyLoad from 'react-lazy-load'


export function MenuContainer() {
    return (
        <>
        <Pricing>
            <Pricing.Header>Menu</Pricing.Header>
            <Pricing.MenuDescription>Each cake is 4 inches in diameter and comes with a complimentary spoon. Feeds 1-3 people. Refrigerate within one hour. Good for up to 5 days! </Pricing.MenuDescription>
            <Pricing.MenuDescription>Limit: 2 bento cakes & 12 cookies per order.</Pricing.MenuDescription>
            <Pricing.MenuDescription>For big orders (5 or more bentos / 6 inch cakes / 9 inch cakes), please DM me on Instagram!</Pricing.MenuDescription>
            <br></br>
            {/* Custom Bento Cakes */}
            <Pricing.CategoryName>Custom Bento Cakes | $25 and up</Pricing.CategoryName>
            <Pricing.CategoryDescription>* Customizations allowed. Prices depend on complexity of the design. Refer to the gallery section for examples.</Pricing.CategoryDescription>
            <Pricing.Custom>
                <Pricing.CustomGallery>
                    {menuCustomData.map((item) => (<LazyLoad><Pricing.CustomImage key={item.id} src={item.image} alt={item.alt}/></LazyLoad>))}
                </Pricing.CustomGallery>
                <Pricing.CustomItems>
                    {flavorsData.map((item) => (
                        <> {item.specialty === false ? 
                            <>
                                <Pricing.CustomItem key={item.id}>
                                    <Pricing.Info>
                                        <Pricing.ItemName>{item.title}</Pricing.ItemName>
                                        <Pricing.CustomDescription>{item.description}</Pricing.CustomDescription>
                                    </Pricing.Info>
                                </Pricing.CustomItem>
                            </> : null}     
                        </>
                    ))}
                </Pricing.CustomItems>
            </Pricing.Custom>
            <br></br>
            {/* Specialty Bento Cakes */}
            <Pricing.CategoryName>Specialty Bento Cakes | $26-$32</Pricing.CategoryName>
            <Pricing.CategoryDescription>* CANNOT be customized with any designs.</Pricing.CategoryDescription>
            <Pricing.Category>

                {flavorsData.map((item) => (
                    <> {item.specialty === true ? 
                        <>
                            <Pricing.Item key={item.id}>
                                <Pricing.Info>
                                    <Pricing.ItemTitle><Pricing.ItemName>{item.title}</Pricing.ItemName><Pricing.Price> {item.price}</Pricing.Price></Pricing.ItemTitle>
                                    <Pricing.Description>{item.description}</Pricing.Description>
                                    <LazyLoad><Pricing.Image src={item.image}/></LazyLoad>
                                </Pricing.Info>
                            </Pricing.Item>
                        </> : null}
                    </>
                ))}
            </Pricing.Category>
            <br></br>
            {/* Cookies */}
            <Pricing.CategoryName>Cookies | 6 for $18 or 12 for $34</Pricing.CategoryName>
            <Pricing.Category>
                {cookiesData.map((item) => (
                    <>
                        {item.cookies && item.image ?
                            <Pricing.Item key={item.id}>
                                <Pricing.Info>
                                    <Pricing.ItemName>{item.title}</Pricing.ItemName>
                                    <Pricing.Description>{item.description}</Pricing.Description>
                                    <LazyLoad><Pricing.Image src={item.image} alt={item.alt}/></LazyLoad>
                                </Pricing.Info>
                            </Pricing.Item>
                        : item.name === "ttcookies" ?
                        <Pricing.Item key={item.id}>
                            <Pricing.Info>
                                <Pricing.ItemName>{item.title}</Pricing.ItemName>
                                <Pricing.Description>{item.description}</Pricing.Description>
                            </Pricing.Info>
                        </Pricing.Item> : null}
                    </>
                ))}
            </Pricing.Category>     
            <br></br>
            {/* Kawaii */}
            <Pricing.CategoryName>Kawaii Cookies | 6 for $22, 12 for $42</Pricing.CategoryName>
            <Pricing.Category>
                {cookiesData.map((item) => (
                    <>
                        {item.kawaii ?
                            <Pricing.Item key={item.id}>
                                <Pricing.Info>
                                    <Pricing.ItemName>{item.title}</Pricing.ItemName>
                                    <Pricing.Description>{item.description}</Pricing.Description>
                                    <LazyLoad><Pricing.Image src={item.image} alt={item.alt}/></LazyLoad>

                                </Pricing.Info>
                            </Pricing.Item>
                        : null}
                    </>
                ))}
            </Pricing.Category>   
      

        </Pricing>
        </>
    )
}
