import React, {useState} from 'react'
import  {Navbar} from '../components'
import * as ROUTES from '../constants/routes' 

export function NavbarContainer({children}) {
    const [open, setOpen] = useState(false)

    function handleClick() {
        setOpen(!open)
    }
    
    return (
        <>
            <Navbar>
                <Navbar.LogoLink to={ROUTES.HOME}>
                        <Navbar.Logo src="/images/logo_noSJ.png" alt="logo"/>
                </Navbar.LogoLink>
                
                <Navbar.Bars onClick={handleClick} />
                <Navbar.Menu open={open}>
                    {open && <Navbar.Times onClick={handleClick} />}
                        <Navbar.ItemLink exact to={ROUTES.HOME}>Home</Navbar.ItemLink>
                        <Navbar.ItemLink to={ROUTES.ABOUT}>About</Navbar.ItemLink>
                        <Navbar.ItemLink to={ROUTES.MENU}>Menu</Navbar.ItemLink>
                        <Navbar.ItemLink to={ROUTES.GALLERY}>Gallery</Navbar.ItemLink>
                        <Navbar.ItemLink to={ROUTES.ORDER}>Order</Navbar.ItemLink>
                        <Navbar.ItemLink to={ROUTES.FAQ}>FAQs</Navbar.ItemLink>
                </Navbar.Menu>
                    {children}
            </Navbar>
        </>
    )
}
