import React from 'react'
import { NavbarContainer } from '../containers/navbar'
import { JumbotronContainer } from '../containers/jumbotron'
import { FooterContainer } from '../containers/footer'
import { HomeGalleryContainer } from '../containers/homegallery'
import {WorksContainer} from '../containers/works'

export default function Home() {
    return (
            <>
                <NavbarContainer />
                <JumbotronContainer />  
                <HomeGalleryContainer /> 
                <WorksContainer />
                <FooterContainer />
            </>
        )
}
