import React, {useState} from 'react'
import {Faq} from "../components"
import { Works } from "../components";
import faqs from "../fixtures/faqs.json"


export function FaqContainer({children}) {
    const [open, setOpen] = useState(false)

    function handleClick(index) {
        setOpen(open === index ? false : index)
    }

    return (
        <>
        <Faq>
            <Faq.Header>
                FAQs
            </Faq.Header>
            
            {/* ORDERING */}
            <br/>
            <Faq.Section>
                <Faq.Category>Ordering</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "ordering" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>

            {/* PAYMENT */}
            <br/>
            <Faq.Section>
                <Faq.Category>Payment</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "payment" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>

            {/* PICKUP */}
            <br/>
            <Faq.Section>
                <Faq.Category>Pickup</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "pickup" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>

            {/* LATE FEE */}
            <br/>
            <Faq.Section>
                <Faq.Category>Late Fee</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "late" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>
            
            {/* HOW TO STORE */}
            <br/>
            <Faq.Section>
                <Faq.Category>How to Store</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "howto" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>

            {/* OTHER */}
            <br/>
            <Faq.Section>
                <Faq.Category>Other</Faq.Category>
                    {faqs.map((item, index)=> (
                        <>
                        {item.category === "other" ? 
                        <>
                            <Faq.Line />
                            <Faq.Item onClick={()=>handleClick(index)} key={index}>
                                <Faq.QGroup>
                                    <Faq.Question>{item.question}</Faq.Question>
                                    {open === index ? <Faq.Close /> : <Faq.Expand />}
                                </Faq.QGroup>
                                    {open === index ? <Faq.Answer open={open}>{item.answer}</Faq.Answer> : null} 

                                </Faq.Item>
                        </> : ""}
                        </>
                    ))}
            </Faq.Section>
        </Faq>
        </>
    )
}

