import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    background-color: #FDE6DC;
    max-width: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: 2em 3em;

    @media screen and (max-width: 1375px ) {
        flex-direction: column-reverse;
    }

    @media screen and (max-width: 620px) {
        padding: 2em 1em;
    }

    @media screen and (max-width: 400px) {
        padding: 1em 0em;
    }
`;

export const Header = styled.h1`
    display: flex;
    color: #364045;
    font-size: 3em;
    text-align: center;
    width: 15%;

    @media screen and (max-width: 1375px) {
        width: auto;
    }

    @media screen and (max-width: 620px) {
        font-size: 2.8em;
    }

    @media screen and (max-width: 400px) {
        font-size: 2.5em;
    }
`;

export const ImageGallery = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const Category = styled.div`
    display: flex;
    padding-right: 1.5em;
    position: relative;

    @media screen and (max-width: 767px) {
        padding: 0 1.5em;
    }

    @media screen and (max-width: 400px) {
        padding: 0 1em;
    }
`;

export const CategoryName = styled.h2`
    position: absolute;
    top: 65%;
    left: 21.8%;
    transform: translate(-50%, -50%);
    color: #fff6f2;
    z-index: 100;
    font-size: 1.5em;
    width: 5em;
    font-weight: bold;
    background-color: rgba(235, 144, 132, 0.6);
    padding: 0.4em 0;
    padding-left: 1em;

    @media screen and (max-width: 767px) {
        margin-left: 0.8em;
    }
`;

export const Image = styled.img`
    pointer: cursor;
    width: 19em;
    height: 19em;
    border-radius: 2em;
    opacity: 0.75;
    transition: 1s;
    padding: 1em 0;
    margin: 0 auto;

    :hover {
        opacity: 1;
    }
`;

export const ImageLink = styled.a`
    text-decoration: none;
`;

