import React from 'react'
import { FooterContainer } from '../containers/footer'
import {MenuContainer} from "../containers/menu"
import {NavbarContainer} from "../containers/navbar"

export default function Menu() {
    return (
        <>
        <NavbarContainer />
        <MenuContainer />
        <FooterContainer />
        </>
    )
}
