import React from 'react'
import {Container, Header, 
        Info, CategoryDescription, 
        Item, Overlay, ItemName, ItemPrice,
        PicContainer, PicLeft, PicRight,
        CustomImages, CustomSqImages, CustomSqImage,
        SpecialtyImages, SpecialtyImage, SpecialtySqImage, 
        OtherImages, OtherImage, 
        Reviews, ReviewsHeader, ReviewsContainer, ReviewsInfo, Review, Star, Verified, Check, Left, Right} from "./styles/gallery"

export default function Gallery({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

Gallery.Header = function GalleryHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Gallery.CategoryDescription = function GalleryCategoryDescription({children, ...restProps}) {
    return <CategoryDescription {...restProps}>{children}</CategoryDescription>
}

Gallery.Info = function GalleryInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}

Gallery.Item = function GalleryItem({children, ...restProps}) {
    return <Item {...restProps}>{children}</Item>
}

Gallery.PicContainer = function GalleryPicContainer({children, ...restProps}) {
    return <PicContainer {...restProps}>{children}</PicContainer>
}

Gallery.ReviewsContainer = function GalleryReviewsContainer({children, ...restProps}) {
    return <ReviewsContainer {...restProps}>{children}</ReviewsContainer>
}

Gallery.ReviewsInfo = function GalleryReviewsInfo({children, ...restProps}) {
    return <ReviewsInfo {...restProps}>{children}</ReviewsInfo>
}

Gallery.PicLeft = function GalleryPicLeft({children, ...restProps}) {
    return <PicLeft {...restProps}>{children}</PicLeft>
}

Gallery.PicRight = function GalleryPicRight({children, ...restProps}) {
    return <PicRight {...restProps}>{children}</PicRight>
}
Gallery.Overlay = function GalleryOverlay({children, ...restProps}) {
    return <Overlay {...restProps}>{children}</Overlay>
}

Gallery.ItemName = function GalleryItemName({children, ...restProps}) {
    return <ItemName {...restProps}>{children}</ItemName>
}

Gallery.ItemPrice = function GalleryItemPrice({children, ...restProps}) {
    return <ItemPrice {...restProps}>{children}</ItemPrice>
}

Gallery.CustomImages = function GalleryCustomImages({children, ...restProps}) {
    return <CustomImages {...restProps}>{children}</CustomImages>
}

Gallery.CustomSqImages = function GalleryCustomSqImages({children, ...restProps}) {
    return <CustomSqImages {...restProps}>{children}</CustomSqImages>
}


Gallery.CustomSqImage = function GalleryCustomSqImage({...restProps}) {
    return <CustomSqImage {...restProps} />
}

Gallery.SpecialtyImages = function GallerySpecialtyImages({children, ...restProps}) {
    return <SpecialtyImages {...restProps}>{children}</SpecialtyImages>
}

Gallery.SpecialtyImage = function GallerySpecialtyImage({...restProps}) {
    return <SpecialtyImage {...restProps} />
}

Gallery.SpecialtySqImage = function GallerySpecialtySqImage({...restProps}) {
    return <SpecialtySqImage {...restProps} />
}

Gallery.OtherImages = function GalleryOtherImages({children, ...restProps}) {
    return <OtherImages {...restProps}>{children}</OtherImages>
}

Gallery.OtherImage = function GalleryOtherImage({children, ...restProps}) {
    return <OtherImage {...restProps}>{children}</OtherImage>
}

Gallery.Reviews = function GalleryReviews({children, ...restProps}) {
    return <Reviews {...restProps}>{children}</Reviews>
}

Gallery.ReviewsHeader = function GalleryReviewsHeader({children, ...restProps}) {
    return <ReviewsHeader {...restProps}>{children}</ReviewsHeader>
}

Gallery.Review = function GalleryReview({children, ...restProps}) {
    return <Review {...restProps}>{children}</Review>
}

Gallery.Star = function GalleryStar({children, ...restProps}) {
    return <Star {...restProps}>{children}</Star>
}

Gallery.Verified = function GalleryVerified({children, ...restProps}) {
    return <Verified {...restProps}>{children}</Verified>
}

Gallery.Check = function GalleryCheck({children, ...restProps}) {
    return <Check {...restProps}>{children}</Check>
}

Gallery.Right = function GalleryRight({children, ...restProps}) {
    return <Right {...restProps}>{children}</Right>
}

Gallery.Left = function GalleryLeft({children, ...restProps}) {
    return <Left {...restProps}>{children}</Left>
}
