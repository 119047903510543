import styled from "styled-components";
import {FaStar, FaCheckCircle, FaAngleRight, FaAngleLeft } from "react-icons/fa";


export const Container = styled.div`
    width: 100%;
    display: flex;
    font-family: 'Open Sans', sans-serif;
    flex-direction: column;
    background-color: #fff2e8;
    -webkit-tap-highlight-color: transparent;
`;

export const Header = styled.h1`
    display: flex;
    margin: 0 auto;
    color: #364045;
    padding: 1em;
    font-size: 3em;
    font-family: "Roboto", sans-serif;

    :focus {
        outline: none !important;
    }
    
    @media screen and (max-width: 955px) {
        font-size: 2.8em;
      }
  
    @media screen and (max-width: 620px) {
        font-size: 2.6em;
      }

      @media screen and (max-width: 400px) {
        font-size: 2.4em;
      }
    
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    color: #364045;
    font-size: 1em;
    padding: 1em 0;
`;

export const CategoryDescription = styled.p`
    color: #364045;
    padding: 1em 0;
    margin: 0 auto;
    width: 20em;
    font-size: 1em;
`;

export const Item = styled.div`
    position: relative;
    display: flex;
    outline: none;
    -webkit-animation: fadein 3s linear forwards;
    animation: fadein 3s linear forwards;
`;

export const PicContainer = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 845px) {
        display: none;
    }
`;

export const ReviewsContainer = styled.div`
    display: flex;
    margin: 0 auto;
    align-items: center;
`;

export const ReviewsInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;
`;

export const PicRight = styled(FaAngleRight)`
    position: relative;
    right: 6%;
    font-size: 1.45em;
    z-index: 100;
    cursor: pointer;
    border-radius: 50%;
    color: #FFF2E8;
    padding: 0.15em;
    transition: 0.5s;
    
    :hover {
        color: #F1B0A7;
    }
`;

export const PicLeft = styled(FaAngleLeft)`
    position: relative;
    left: 6%;
    font-size: 1.45em;
    z-index: 100;
    cursor: pointer;
    border-radius: 50%;
    color: #FFF2E8;
    transition: 0.5s;
    padding: 0.15em;

    :hover {
        color: #F1B0A7;
    }
    `;

export const CustomImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: auto;
    margin: 0 auto;
    align-items: center;
    padding: 0.25em 3em;
    justify-content: space-between;

    @media screen and (max-width: 855px) {
        padding: 0.25em 3em;
    }

    @media screen and (max-width: 620px) {
        padding: 0.25em 1em;
    }

    @media screen and (max-width: 1215px) {
        flex-direction: column;
        
        :nth-child(odd) {
            flex-direction: column-reverse;
        }
    }
`;

export const CustomSqImages = styled.div`
    display: none;

    @media screen and (max-width: 845px) {
        display: flex;
        width: 40em;
        padding: 0.25em;
        overflow: scroll;
    }

    @media screen and (max-width: 770px) {
        width: 19em;
    }
`;

export const CustomSqImage = styled.img`
    height: 19em;
    width: 19em;
`;

export const SpecialtyImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0 5em;
    
    @media screen and (max-width: 620px) {
        padding: 0 1em;
    }
`;

export const SpecialtyImage = styled.img`
    display: flex;
    width: 13em;
    height: 23em;

    :hover {
        opacity: 1;
    }

    @media screen and (max-width: 1215px) {
        display: none;
    }
`;

export const SpecialtySqImage = styled.img`
    display: none; 

    @media screen and (max-width: 1215px) {
        display: flex;
        flex-wrap: wrap;
        width: 19em;
        height: 19em;
    }
`;

export const OtherImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0 5em;

    @media screen and (max-width: 855px) {
        padding: 0 3em;
    }

    @media screen and (max-width: 620px) {
        padding: 0 1em;
    }
`;

export const OtherImage = styled.img`
    width: 21.65em;
    height: 21.65em;
    
    :hover {
        opacity: 1;
    }

    @media screen and (max-width: 1215px) {
        display: flex;
        flex-wrap: wrap;
        width: 19em;
        height: 19em;
    }
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(241, 176, 167, 0.95);
    color: green;
    z-index: 100;
    text-align: center;

    :hover {
        opacity:1;
    }
`;

export const ItemName = styled.h3`
    color: #FFF6F2;
    font-size: 1em;
    width: 11em;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
`;

export const ItemPrice = styled.p`
    padding: 1em 0;
    font-weight: bold;
    margin: 0 auto;
`;

export const Reviews = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FDE6DC;
    width: 100%;
    padding: 2.5em 0;
`;

export const ReviewsHeader = styled.h2`
    font-size: 1.6em;
    color: #364045;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
`;

export const Review = styled.p`
    font-size: 1em; 
    width: 43em;
    height: 3em;
    text-align: center;
    line-height: 1.8;
    color: #364045;
    margin: 0.5em auto;
    align-items: center;

    @media screen and (max-width: 855px) {
        width: 32em;
        height: 5em;
    }

    @media screen and (max-width: 620px) {
        display: flex;
        flex-wrap: wrap;
        width: 19em;
        height: 9em;
        font-size: 0.95em;
    }
`;

export const Star = styled(FaStar)`
    font-size: 0.6em;
    padding: 0 1em;
    color: #EB9084;
`;

export const Verified = styled.div`
    display: flex;
    font-style: italic;
    font-size: 0.9em;
    padding: 1em 0;
    align-items: center;
`;

export const Check = styled(FaCheckCircle)`
    color: #EB9084;
    padding-right: 0.3em;
`;

export const Right = styled(FaAngleRight)`
    display: flex;
    color: #EB9084;
    font-size: 1.5em;
    cursor: pointer;
    transition: 0.5s;
    
    :hover {
        color: rgba(235, 144, 132, 0.75);
    }
`;

export const Left = styled(FaAngleLeft)`
    display: flex;
    color: #EB9084;
    font-size: 1.5em;
    cursor: pointer;
    transition: 0.5s;

    :hover {
        color: rgba(235, 144, 132, 0.75);
    }
`;