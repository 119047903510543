import React from 'react'
import {Container, Info, Header, Name, Description, Logo, MenuButton, OrderButton} from "./styles/jumbotron"

export default function Jumbotron({children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>
}

Jumbotron.Logo = function JumbotronLogo({...restProps}) {
    return <Logo {...restProps} />
}

Jumbotron.Info = function JumbotronInfo({children, ...restProps}) {
    return <Info {...restProps}>{children}</Info>
}

Jumbotron.Header = function JumbotronHeader({children, ...restProps}) {
    return <Header {...restProps}>{children}</Header>
}

Jumbotron.Name = function FlavorsName({children, ...restProps}) {
    return <Name {...restProps}>{children}</Name>
}


Jumbotron.Description = function JumbotronDescription({children, ...restProps}) {
    return <Description {...restProps}>{children}</Description>
}

Jumbotron.MenuButton = function JumbotronMenuButton({to, children, ...restProps}) {
    return <MenuButton {...restProps} to={to}>{children}</MenuButton>
}

Jumbotron.OrderButton = function JumbotroOrderButton({to, children, ...restProps}) {
    return <OrderButton {...restProps} to={to}>{children}</OrderButton>
}