import React from 'react'
import { NavbarContainer } from '../containers/navbar'
import { AboutContainer } from '../containers/about'
import { FooterContainer } from '../containers/footer'

export default function About() {
    return (
        <div>
            <NavbarContainer />
            <AboutContainer />
            <FooterContainer />
        </div>
    )
}
