import { Works } from "../components";

import React from 'react'

export function WorksContainer() {
    return (
        <>
            <Works>
                <Works.Header>How To Order</Works.Header>
                <Works.Gallery>
                    <Works.Item>
                        <Works.Form />
                        <Works.Info>
                            <Works.Title>1 — Fill Out Order Form</Works.Title>
                            <Works.Description>Fill out the order form once it’s open! Check my availability highlights on Instagram to see the next drop date and pickup dates!</Works.Description>
                        </Works.Info>
                    </Works.Item>

                    <Works.Item>
                        <Works.Wait />
                        <Works.Info>
                            <Works.Title>2 — Wait for Confirmation</Works.Title>
                            <Works.Description>Please wait up to 48 hours to get a response. Orders are processed through a first come, first serve basis. Filling out the form does not guarantee your order.</Works.Description>
                        </Works.Info>
                    </Works.Item>

                    <Works.Item>
                        <Works.Pay />
                        <Works.Info>
                            <Works.Title>3 — Pay On Time</Works.Title>
                            <Works.Description>Payment must be submitted within 24 hours of receiving your invoice. Only Venmo and Zelle are accepted.</Works.Description>
                        </Works.Info>
                    </Works.Item>

                    <Works.Item>
                        <Works.PickUp />
                        <Works.Info>
                            <Works.Title>4 — Pick Up Order</Works.Title>
                            <Works.Description>Arrive at the designated location on time to receive your freshly made treats! Please be considerate and don’t be late.</Works.Description>
                        </Works.Info>
                    </Works.Item>

                    <Works.Item>
                        <Works.Enjoy />
                        <Works.Info>
                            <Works.Title>5 — Enjoy!</Works.Title>
                            <Works.Description>Share with your loved ones or have it all to yourself! Refrigerate within an hour. Cakes last up to five days!</Works.Description>
                        </Works.Info>
                    </Works.Item>
                </Works.Gallery>
            </Works>
        </>
    )
}
