import styled from "styled-components"
import { FaRegCopyright, FaInstagram, FaTiktok } from "react-icons/fa";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #EB9084;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    padding: 1em 5em;
    color: #fff6f2;

    @media screen and (max-width: 620px) {
        padding: 1em;
    }

`;

export const Copyright = styled.h1`
    display: flex;
    float: left;
    font-size: 1em;
    font-weight: normal;

    @media screen and (max-width: 605px) {
        font-size: 0.9em;
    }
`;

export const CopyrightIcon = styled(FaRegCopyright)`
    padding: 0 1em;
`;

export const Socials = styled.div`
    display: flex;
    align-items: center;
`;

export const SocialsLink = styled.a`
    text-decoration: none;
    color: #fff6f2;
`;

export const Instagram = styled(FaInstagram)`
    font-size: 1.5em;
    padding-left: 0.5em;

    @media screen and (max-width: 605px) {
        font-size: 1.3em;
    }

`;

export const Tiktok = styled(FaTiktok)`
    font-size: 1.3em;
    padding-left: 0.5em;

    @media screen and (max-width: 605px) {
        font-size: 1.15em;
    }
`;
