import React from 'react'
import {Container, Copyright, CopyrightIcon, Socials, SocialsLink, Instagram, Tiktok} from "./styles/footer"

export default function Footer({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>   
    )
}

Footer.Copyright = function FooterCopyright({children, ...restProps}) {
    return <Copyright {...restProps}>{children}</Copyright>
}

Footer.CopyrightIcon = function FooterCopyrightIcon({children, ...restProps}) {
    return <CopyrightIcon {...restProps}>{children}</CopyrightIcon>
}

Footer.Socials = function FooterSocials({children, ...restProps}) {
    return <Socials {...restProps}>{children}</Socials>
}

Footer.SocialsLink = function FooterSocialsLink({children, ...restProps}) {
    return <SocialsLink {...restProps}>{children}</SocialsLink>
}

Footer.Instagram = function FooterInstagram({children, ...restProps}) {
    return <Instagram {...restProps}>{children}</Instagram>
}

Footer.Tiktok = function FooterTiktok({children, ...restProps}) {
    return <Tiktok {...restProps}>{children}</Tiktok>
}