import React from 'react'
import { FooterContainer } from '../containers/footer'
import { GalleryContainer } from '../containers/gallery'
import { NavbarContainer } from '../containers/navbar'
import tier1 from "../fixtures/tier1.json"
import tier2 from "../fixtures/tier2.json"
import tier3 from "../fixtures/tier3.json"
import tier4 from "../fixtures/tier4.json"
import reviewsData from "../fixtures/reviewsData.json"

export default function Gallery() {
    return (
        <>
            <NavbarContainer /> 
            <GalleryContainer reviews={reviewsData} tier1={tier1} tier2={tier2} tier3={tier3} tier4={tier4} />
            <FooterContainer />
        </>
    )
}
