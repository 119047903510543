import styled from "styled-components"
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFF2E8;
    padding: 2em 3em;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;


    @media screen and (max-width: 620px) {
        padding: 2em 1em;
    }

    @media screen and (min-width:1441px) {
        height: 280vh;
    }
`;

export const Header = styled.h1`
    color: #364045;
    line-height: 1.5;
    margin: 0 auto;
`;

export const Section = styled.div`
    width: 75%;
    margin: 0 auto;
`;

export const QGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const Category = styled.h3`
    color: #EB9084;
`;

export const Expand = styled(FaPlusCircle)`
    font-size: 20px;
    color:#EB9084;
    display: block;
`;

export const Close = styled(FaMinusCircle)`
    font-size: 20px;
    color:#EB9084;
    display: block;
`;

export const Line = styled.hr``;

export const Answer = styled.p`
    display: flex;
    color: #364045;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    padding-bottom: 1em;
    white-space: pre-line;

`;

export const Question = styled.h4`
    color: #364045;
    width: 80%;
    }
`;