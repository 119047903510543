import styled from 'styled-components'
import {NavLink as Link} from "react-router-dom"
import { FaBars, FaTimes } from 'react-icons/fa';

export const Nav = styled.nav`
    display: flex;
    background-color: #FFF2E8;
    width: 100%;
    height: 90px;
    display: flex;
    overflow: hidden;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    justify-content: space-between;
    z-index: 100;
`;

export const Logo = styled.img`
    width: 14em;
    height: 8em;
    padding-top: 1.5em;
    padding-left: 2em;

    @media screen and (max-width: 620px) {
      padding-left: 2em;
  }
    `;



export const Bars = styled(FaBars)`
    display: none;
    color: #969696;
    
    @media screen and (max-width: 905px) {
      display: block;
      color: #EEA297;
      position: absolute;
      top: 25px;
      right: 1em;
      transform: translate(-100%, 75%);
      font-size: 1.8rem;
      z-index: 1;
      transition: 2s;
      cursor: pointer;
    }

    :hover {
      color: #F1B0A7;
    }
  `;

export const Times = styled(FaTimes)`
      display: none; 
      
      @media screen and (max-width: 768px) {
        display: block;
        color: #eb9084;
        position: absolute;
        top: 25px;
        right: 40px;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        z-index: 1;
        transition: 0.5s;

        :hover {
          color: #F1B0A7;
        }
}

`;
export const Menu = styled.ul`
    display: flex;
    padding-top: 1em;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    padding-right: 2em;
    cursor: pointer;

    /* phone screen */ 
    @media screen and (max-width: 905px) { 
      display: ${({open}) => open ? "block" : "none"};
      margin: 0;
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: #fff2e8;
      overflow: hidden; 
      padding: 20px;
      transition: 0.5s; 
    }
  `;

export const ItemLink = styled(Link)`
    position: relative;
    display: block;
    text-decoration: none;
    padding: 12px 0;
    margin-left: 2em;
    cursor: pointer;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #EB9084;
    width: 3.4em;
    text-align: left;

    &:hover {
        border-bottom: 5px #FDE6DC solid;
    }
    
    &.active {
        border-bottom: 0.25em #EB9084 solid;
        width: 3.4em;
        color: #364045;
    }
`;

export const ButtonLink = styled(Link)`
    display: flex;
    position: absolute;
    padding: 12px 24px;
    background-color: #ff8c80;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    right: 20px;
    transition: 1s;

    :hover {
        background-color: #ffafa6;
    }
    `;

    export const LogoLink = styled(Link)``;
